import React from 'react';
import {
  arrayOf,
  object,
  string,
} from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
}) => {
  const { site } = useStaticQuery(graphql`
    query SiteSEOQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          share_image
          keywords
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  // the image url has to be an absolute url with http:// or https://
  // relative links do not work
  const image = metaImage && metaImage.src
    ? site.siteMetadata.siteUrl.concat(metaImage.src)
    : site.siteMetadata.siteUrl.concat(site.siteMetadata.share_image);

  return (
    <Helmet
      htmlAttributes={ { lang } }
      title={ title }
      titleTemplate={ `%s | ${site.siteMetadata.title}` }
      meta={ [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(','),
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.title,
        },
      ]
        .concat(meta) }
    >
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  image: null,
};

SEO.propTypes = {
  description: string,
  lang: string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: arrayOf(object),
  title: string.isRequired,
  image: string,
};

export default SEO;
